<template>
  <div id="upload" class="upload-div">
    <p>Thank you for helping to assist the appraiser in completing your home valuation.  This should take less than 15 minutes of your time, depending on the size of your property.</p>
      <p>The appraiser needs photos of your property.  Please take a picture of the FRONT of the property, and try to include the street number in the photo.  Next take a picture looking down the STREET in front of the property.  And last, take a picture of the REAR of the property.</p>
      <p>You can also take as many other pictures of the property as you like, labeling each with the options available.  Please note there is a 5mb size limit per picture, and that once you have submitted your pictures, this page will be deactivated.</p>
      <el-form ref="form360" :model="form360" method="POST" enctype='multipart/form-data' label-width="120px">
          <input type="hidden" name="token" v-model="form360.token"/>
      <el-upload
        ref="fileupload"
        v-model="form360.files"
        name="files"
        class="upload-demo"
        :action=actionurl
        :on-preview="handlePreview"
        :before-remove="beforeRemove"
        :multiple="true"
        drag
        :limit="45"
        :on-exceed="handleExceed"
        list-type="picture"
        :before-upload="beforeAvatarUpload"
        :on-success="handleAvatarSuccess"
        @keydown="removeLastTokenIfEmpty($event)"
      >

        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          Drop file here or
          <em>click to upload</em>
        </div>
        <div class="el-upload__tip" slot="tip">
            jpeg/jpg/gif/png files with a size less than 5Mb
        </div>

        <div
          slot="file"
          slot-scope="{file}"
          v-on:keyup="handleDelete"
          v-on:keydown="handleDelete"
        >
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
          <el-cascader
            placeholder="Pleases select image description"
            :options="current_opt"
            class="testclass"
            :props="{ expandTrigger: 'hover',checkStrictly: false }"
            change-on-select
            @change="onElCascasderChange($event, file)"
          ></el-cascader>
          <input type="hidden" name="filesids" :value="form360.filesids[file.uid]"/>
          <input type="hidden" name="typename" :value="form360.typenames[file.uid]"/>
          <span v-show="isOthervisible(file.uid)">
            <br>
            <input type="text" @change="onElOtherNameChange($event, file.uid)" name="othername" />
          </span>

          <label class="el-upload-list__item-status-label">
            <i class="el-icon-upload-success el-icon-check"></i>
          </label>
          <i class="el-icon-close" @click="onRemoveClicked(file)"></i>
          <i class="el-icon-close-tip">press delete to remove</i>
        </div>
      </el-upload>
          <p>{{ form360.totalfile }}/45 total file uploaded</p>
      <input type="checkbox" id="legalcheckbox" v-model="form360.legalcheck">
      <label for="legalcheckbox">&nbsp;I acknowledge that any uploaded digital photographs of my property may be used to complete an appraisal report of the property. The appraisal report may contain these photographs and will be delivered to the financial institution (lender) for its internal business purposes and I consent to such use of my photographs.</label>
      <div class="centerbtn"><el-button type="primary" @click="onSubmit">Submit</el-button></div>

    </el-form>
  </div>
</template>

<script>
export default {
  name: "UploadPage",
  data() {
    return {
      form360: {
        legalcheck : false,
        filesids: {},
        typenames: {},
        othernames: {},
        totalfile:0
      },
      unable_exterior : false,
      actionurl : '',
      othervisible: {},
      current_opt:[],
      extoptions:[
          {
              value: "exterior_photos",
              label: "Exterior Photos (*)",
              children: [
                  { value: "front_view", label: "Front View (*)" },
                  { value: "rear_view", label: "Rear View (*)" }
              ]
          },
          {
              value: "street_photos",
              label: "Street Photos (*)",
              children: [{ value: "street_view", label: "Street View (*)" }]
          },
          {
              value: "above_grade",
              label: "Above Grade or Main floor",
              children: [
                  { value: "entrance", label: "Entrance/Foyer" },
                  { value: "kitchen", label: "Kitchen" },
                  { value: "living_Room", label: "Living Room(s)" },
                  { value: "family_room", label: "Family Room(s)" },
                  { value: "dining_room", label: "Dining Room" },
                  {
                      value: "master_bathroom",
                      label: "Master Bathroom – Ensuite (Full)"
                  },
                  { value: "bathroom_full", label: "Bathroom(s) – Full" },
                  { value: "bathroom_half", label: "Bathroom(s) – Half" },
                  { value: "master_bedroom", label: "Master Bedroom" },
                  { value: "walk_in_closet", label: "Walk-In Closet" },
                  { value: "bedroom", label: "Bedroom(s)" },
                  { value: "den_office", label: "Den/Office" },
                  { value: "loft", label: "Loft" },
                  { value: "laundry_room", label: "Laundry Room" },
                  { value: "utility_storage_room", label: "Utility/Storage Room" },
                  { value: "other", label: "Other" }
              ]
          },
          {
              value: "below_grade",
              label: "Below Grade (Basement)",
              children: [
                  { value: "basement_kitchen", label: "Basement Kitchen" },
                  { value: "living_Room", label: "Living Room(s)" },
                  { value: "bathroom_full", label: "Bathroom(s) – Full" },
                  { value: "bathroom_half", label: "Bathroom(s) – Half" },
                  { value: "bedrooms", label: "Bedroom(s)" },
                  { value: "den_office", label: "Den/Office" },
                  { value: "laundry_room", label: "Laundry Room" },
                  { value: "utility_storage_room", label: "Utility/Storage Room" },
                  { value: "other", label: "Other" }
              ]
          },
          {
              value: "extra_features",
              label: "Extra Features",
              children: [
                  { value: "inground_pool", label: "Inground Pool" },
                  { value: "above_ground_pool", label: "Above Ground Pool" },
                  {
                      value: "upgraded_professional_landscaping",
                      label: "Upgraded/Professional Landscaping"
                  },
                  { value: "deck", label: "Deck" },
                  { value: "shed", label: "Shed" },
                  { value: "solar_panels", label: "Solar Panels" },
                  { value: "acreage", label: "Acreage" },
                  { value: "accessible_home", label: "Accessible Home" },
                  { value: "other", label: "Other (please specify)" }
              ]
          }
      ],
      options: [
        {
          value: "above_grade",
          label: "Above Grade or Main floor",
          children: [
            { value: "entrance", label: "Entrance/Foyer" },
            { value: "kitchen", label: "Kitchen" },
            { value: "living_Room", label: "Living Room(s)" },
            { value: "family_room", label: "Family Room(s)" },
            { value: "dining_room", label: "Dining Room" },
            {
              value: "master_bathroom",
              label: "Master Bathroom – Ensuite (Full)"
            },
            { value: "bathroom_full", label: "Bathroom(s) – Full" },
            { value: "bathroom_half", label: "Bathroom(s) – Half" },
            { value: "master_bedroom", label: "Master Bedroom" },
            { value: "walk_in_closet", label: "Walk-In Closet" },
            { value: "bedroom", label: "Bedroom(s)" },
            { value: "den_office", label: "Den/Office" },
            { value: "loft", label: "Loft" },
            { value: "laundry_room", label: "Laundry Room" },
            { value: "utility_storage_room", label: "Utility/Storage Room" },
            { value: "other", label: "Other" }
          ]
        },
        {
          value: "below_grade",
          label: "Below Grade (Basement)",
          children: [
            { value: "basement_kitchen", label: "Basement Kitchen" },
            { value: "living_Room", label: "Living Room(s)" },
            { value: "bathroom_full", label: "Bathroom(s) – Full" },
            { value: "bathroom_half", label: "Bathroom(s) – Half" },
            { value: "bedrooms", label: "Bedroom(s)" },
            { value: "den_office", label: "Den/Office" },
            { value: "laundry_room", label: "Laundry Room" },
            { value: "utility_storage_room", label: "Utility/Storage Room" },
            { value: "other", label: "Other" }
          ]
        },
        {
          value: "extra_features",
          label: "Extra Features",
          children: [
            { value: "inground_pool", label: "Inground Pool" },
            { value: "above_ground_pool", label: "Above Ground Pool" },
            {
              value: "upgraded_professional_landscaping",
              label: "Upgraded/Professional Landscaping"
            },
            { value: "deck", label: "Deck" },
            { value: "shed", label: "Shed" },
            { value: "solar_panels", label: "Solar Panels" },
            { value: "acreage", label: "Acreage" },
            { value: "accessible_home", label: "Accessible Home" },
            { value: "other", label: "Other (please specify)" }
          ]
        }
      ]
    };
  },
  created() {
      this.form360.token = document.getElementById('token').value;
      this.unable_exterior = document.getElementById('unable_exterior').value;
      this.actionurl = "/tandem/remote/SaveVirtualInspectionPhoto?token=" + this.form360.token;
      this.current_opt = this.options;
      if(this.unable_exterior == 't'){
          this.current_opt = this.extoptions;
      }
  },
  methods: {
      checkIsEmpty(obj) {
          for(var key in obj) {
              if(Object.prototype.hasOwnProperty.call(obj, key))
                  return false;
          }
          return true;
      },
    onSubmit() {
        if(!this.form360.legalcheck){
            this.$message.error("Please check the acknowledge ");
            return;
        }else{
            if(this.checkIsEmpty(this.form360.filesids)) {
                this.$message.error("Please uploads files before submit ");
                return;
            }else{
                if(this.checkIsEmpty(this.form360.typenames)) {
                    this.$message.error("Please select image description ");
                    return;
                }
            }
            if(this.unable_exterior == 't'){
                //check for ext photo uploaded
                let ext_front = false;
                let ext_rear = false;
                let str_str = false;
                $.each(this.form360.typenames, function(key, value) {
                    if(value == 'exterior_photos/front_view') ext_front = true;
                    if(value == 'exterior_photos/rear_view') ext_rear = true;
                    if(value == 'street_photos/street_view') str_str = true;
                });
                if(!ext_front){
                    this.$message.error("Please upload one image for Exterior Photos / Front View");return;
                }
                if(!ext_rear){
                    this.$message.error("Please upload one image Exterior Photos / Rear View");return;
                }
                if(!str_str){
                    this.$message.error("Please upload one image Street Photos / Street View ");return;
                }
            }

            this.axios.post('/tandem/remote/SaveVirtualInspectionData', {
                form360Data: this.form360
            })
            .then(response => {
                if(response.data.Status){
                    window.location = "tandem/cnx-360-thank";
                } else{
                    this.$message.error("Some error while saving data,Please try again");
                }
            })
            .catch(e => {
                console.log("Some error while saving data,Please try again");
            })
        }
    },
    handleDelete(event) {
      if (event.keyCode === 46 || event.keyCode === 8) {
        event.stopImmediatePropagation();
        return false;
      }
    },
    onElOtherNameChange(event, uid) {
      this.$set(this.form360.othernames, uid, event.target.value);
    },
    onElCascasderChange(item, file) {
      var thslabel = item.join("/");
      var uid = file.uid;
      this.$set(this.form360.typenames, uid, thslabel);

      //if its first selection then no need to do anything
        if (
            thslabel == "exterior_photos" ||
            thslabel == "street_photos" ||
            thslabel == "above_grade" ||
            thslabel == "below_grade" ||
            thslabel == "extra_features"
        ){
            return;
        }
      if (
        thslabel == "above_grade/other" ||
        thslabel == "below_grade/other" ||
        thslabel == "extra_features/other"
      ) {
        this.$set(this.othervisible, uid, true);
      } else {
            this.$set(this.othervisible, uid, false);
      }
    },
    isOthervisible(uid) {
      return this.othervisible[uid];
    },
    beforeAvatarUpload(file) {
      const isJPG = ((file.type === 'image/jpeg') || (file.type ==='image/jpg') || (file.type ==='image/gif') || (file.type ==='image/png'));
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("Image must be jpeg/jpg/gif/png format!");
      }
      if (!isLt5M) {
        this.$message.error("Image size can not exceed 5MB!");
      }
      return isJPG && isLt5M;
    },
    onRemoveClicked(file) {
      this.$set(this.form360.othernames, file.uid, undefined);
      this.$set(this.othervisible, file.uid, undefined);
      this.$set(this.form360.typenames, file.uid, undefined);
      this.$set(this.form360.filesids, file.uid, undefined);
      this.form360.totalfile = this.form360.totalfile -1;
      this.$refs.fileupload.handleRemove(file);

      return true;
    },
    handlePreview(file) {
    },
    handleExceed(files, fileList) {
        this.$message.warning('You cross 45 Max Limit of Photo');
    },
    beforeRemove(file, fileList) {
    },
      handleAvatarSuccess(res, file) {
          if(res.Status){
              this.$set(this.form360.filesids, file.uid, res.fileid);
              this.form360.totalfile = this.form360.totalfile +1;
          } else{
              this.$message.error(res.Message);
              this.$refs.fileupload.handleRemove(file);
            }
      }
  }
};
</script>
<style >
.overflow-visible {
    white-space: nowrap;
    overflow: hidden;
    white-space: initial;
}
.upload-demo {
  width: 100%;
}
.el-upload {
  width: 100%;
}
.el-upload-dragger {
  width: 100% !important;
}
.testclass {
  width: 70%;
}
.el-upload-list--picture .el-upload-list__item{
    height: 100px !important;
}
.centerbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
}
</style>
