import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import UploadPage from '../../components/UploadPage';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;

Vue.use(VueAxios, axios)
Vue.use(ElementUI,{locale});

new Vue({
    render: h => h(UploadPage),
}).$mount('#upload360');
